import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import {
  Container, Typography, Grid, Button, TextField, Select, MenuItem, List, ListItem, ListItemText,
  IconButton, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Accordion,
  AccordionSummary, AccordionDetails, Divider
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

// Function to load environment variables from env.js
const loadEnvVariables = () => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = '/env.js';
    script.onload = () => {
      resolve(window.env);
    };
    document.head.appendChild(script);
  });
};

const App = () => {
  const [systems, setSystems] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [systemToDelete, setSystemToDelete] = useState(null);
  const [currentSystem, setCurrentSystem] = useState({});
  const [expandedAccordion, setExpandedAccordion] = useState({});
  const [expandedSubAccordion, setExpandedSubAccordion] = useState({});
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [env, setEnv] = useState({});

  useEffect(() => {
    loadEnvVariables().then((env) => {
      setEnv(env);
    });
  }, []);

  const fetchSystems = useCallback(async () => {
    const res = await axios.get(`${env.REACT_APP_API_URL}/api/systems`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setSystems(res.data);
  }, [token, env]);

  const fetchStatuses = useCallback(async () => {
    const res = await axios.get(`${env.REACT_APP_API_URL}/api/status`);
    setStatuses(res.data);
  }, [env]);

  useEffect(() => {
    if (token && env.REACT_APP_API_URL) {
      fetchSystems();
      fetchStatuses();
      const interval = setInterval(fetchStatuses, 5000);
      return () => clearInterval(interval);
    }
  }, [token, env, fetchSystems, fetchStatuses]);

  const handleDelete = (systemId) => {
    setSystemToDelete(systemId);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteSystem(systemToDelete);
      fetchSystems();
      setDeleteDialogOpen(false);
      alert("System wurde erfolgreich gelöscht!");
    } catch (error) {
      console.error(error);
      alert("Fehler beim Löschen des Systems!");
    }
  };

  const cancelDelete = () => {
    setDeleteDialogOpen(false);
    setSystemToDelete(null);
  };

  const addSystem = useCallback(async (system) => {
    await axios.post(`${env.REACT_APP_API_URL}/api/systems`, system, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    fetchSystems();
  }, [token, env, fetchSystems]);

  const deleteSystem = async (id) => {
    await axios.delete(`${env.REACT_APP_API_URL}/api/systems/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    fetchSystems();
  };

  const updateSystem = useCallback(async (id, system) => {
    await axios.put(`${env.REACT_APP_API_URL}/api/systems/${id}`, system, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    fetchSystems();
  }, [token, env, fetchSystems]);

  const handleOpen = (system = {}) => {
    setCurrentSystem(system);
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentSystem({});
    setOpen(false);
  };

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setCurrentSystem(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (currentSystem._id) {
      updateSystem(currentSystem._id, currentSystem);
    } else {
      if (!currentSystem.type) {
        currentSystem.type = 'ping';
      }
      addSystem(currentSystem);
    }
    handleClose();
  }, [currentSystem, updateSystem, addSystem]);

  const getStatusChipColor = (status) => {
    switch (status) {
      case 'online':
      case 'healthy':
      case 'running':
        return 'success';
      case 'starting':
        return 'warning';
      default:
        return 'error';
    }
  };

  const getOverallStatus = (statuses) => {
    if (statuses.some(s => s.status === 'offline')) return 'offline';
    if (statuses.some(s => s.status === 'starting')) return 'starting';
    if (statuses.every(s => s.status === 'running' || s.status === 'healthy' || s.status === 'online')) return 'online';
    return 'unknown';
  };

  const renderServiceStatus = (services) => (
    services.map(service => (
      <ListItem key={service.name}>
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <ListItemText primary={service.name} />
          </Grid>
          <Grid item xs={3}>
            <Chip label={service.status} color={getStatusChipColor(service.status)} sx={{
              "& .MuiChip-label": {
                color: "white",
                fontSize: '13px',
                fontWeight: 'bold',
              }
            }} />
          </Grid>
        </Grid>
      </ListItem>
    ))
  );

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(prev => ({ ...prev, [panel]: isExpanded }));
  };

  const handleSubAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedSubAccordion(prev => ({ ...prev, [panel]: isExpanded }));
  };

  const handleLogin = async (username, password) => {
    try {
      const res = await axios.post(`${env.REACT_APP_API_URL}/auth/login`, { username, password });
      setToken(res.data.token);
      localStorage.setItem('token', res.data.token);
    } catch (error) {
      console.error(error);
      alert('Login failed');
    }
  };

  const handleLogout = () => {
    setToken('');
    localStorage.removeItem('token');
  };

  const SystemList = ({ systems, onDelete, onEdit, statuses, expandedAccordion, handleAccordionChange, expandedSubAccordion, handleSubAccordionChange }) => (
    <List>
      {systems.map(system => {
        const statusObj = statuses.find(s => s._id === system._id);
        const status = statusObj ? statusObj.status : 'unknown';

        if (Array.isArray(status)) {
          const overallStatus = getOverallStatus(status.flatMap(endpoint => endpoint.status));

          return (
            <div key={system._id}>
              <ListItem>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <ListItemText
                      primary={`${system.name} (${system.type}) - ${system.address}${system.port ? `:${system.port}` : ''}`}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Chip label={overallStatus} color={getStatusChipColor(overallStatus)} sx={{
                      "& .MuiChip-label": {
                        color: "white",
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }
                    }} />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => onEdit(system)}><EditIcon /></IconButton>
                    <IconButton onClick={() => onDelete(system._id)}><DeleteIcon /></IconButton>
                  </Grid>
                </Grid>
              </ListItem>
              <Accordion expanded={expandedAccordion[system._id]} onChange={handleAccordionChange(system._id)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Endpoints</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {status.map(endpoint => (
                      <Accordion
                        key={endpoint.endpointId}
                        expanded={expandedSubAccordion[endpoint.endpointId]}
                        onChange={handleSubAccordionChange(endpoint.endpointId)}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{endpoint.endpointName}</Typography>
                          <Chip label={getOverallStatus(endpoint.status)} color={getStatusChipColor(getOverallStatus(endpoint.status))} sx={{
                            marginLeft: 2,
                            "& .MuiChip-label": {
                              color: "white",
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }
                          }} />
                        </AccordionSummary>
                        <AccordionDetails>
                          <List>
                            {renderServiceStatus(endpoint.status)}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
              <Divider />
            </div>
          );
        }

        return (
          <div key={system._id}>
            <ListItem>
              <Grid container alignItems="center">
                <Grid item xs={9}>
                  <ListItemText
                    primary={`${system.name} (${system.type})${system.address ? ` - ${system.address}` : ''}${system.port ? `:${system.port}` : ''}`}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Chip label={status} color={getStatusChipColor(status)} sx={{
                    "& .MuiChip-label": {
                      color: "white",
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }
                  }} />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => onEdit(system)}><EditIcon /></IconButton>
                  <IconButton onClick={() => onDelete(system._id)}><DeleteIcon /></IconButton>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
          </div>
        );
      })}
    </List>
  );

  const SystemDialog = useMemo(() => {
    return ({ open, onClose, system, onChange, onSubmit }) => (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{system._id ? 'Edit System' : 'Add System'}</DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={system.name || ''}
              onChange={onChange}
            />
            <Select
              name="type"
              value={system.type || 'ping'}
              onChange={onChange}
              fullWidth
            >
              <MenuItem value="ping">Ping</MenuItem>
              <MenuItem value="tcp">TCP</MenuItem>
              <MenuItem value="portainer">Portainer</MenuItem>
              <MenuItem value="website">Website</MenuItem>
            </Select>
            <TextField
              margin="dense"
              name="address"
              label="Address"
              type="text"
              fullWidth
              value={system.address || ''}
              onChange={onChange}
            />
            {(system.type === 'tcp' || system.type === 'portainer' || system.type === 'website') && (
              <TextField
                margin="dense"
                name="port"
                label="Port"
                type="number"
                fullWidth
                value={system.port || ''}
                onChange={onChange}
              />
            )}
            {(system.type === 'portainer') && (
              <>
                <TextField
                  margin="dense"
                  name="apiKey"
                  label="API Key"
                  type="text"
                  fullWidth
                  value={system.apiKey || ''}
                  onChange={onChange}
                />
                <TextField
                  margin="dense"
                  name="endpoint"
                  label="Endpoint"
                  type="text"
                  fullWidth
                  value={system.endpoint || ''}
                  onChange={onChange}
                />
              </>
            )}
            {(system.type === 'website') && (
              <TextField
                margin="dense"
                name="keywords"
                label="Keywords (comma separated)"
                type="text"
                fullWidth
                value={system.keywords || ''}
                onChange={onChange}
              />
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={onSubmit} color="primary">{system._id ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    );
  }, []);


  const LoginForm = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
      e.preventDefault();
      onLogin(username, password);
    };

    return (
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">Login</Button>
      </form>
    );
  };

  if (!token) {
    return (
      <Container>
        <Typography variant="h3" gutterBottom>Login</Typography>
        <LoginForm onLogin={handleLogin} />
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h3" gutterBottom>System Status Monitor</Typography>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleOpen()}>Add System</Button>
      <Button variant="contained" color="secondary" onClick={handleLogout}>Logout</Button>
      <SystemList
        systems={systems}
        onDelete={handleDelete}
        onEdit={handleOpen}
        statuses={statuses}
        expandedAccordion={expandedAccordion}
        handleAccordionChange={handleAccordionChange}
        expandedSubAccordion={expandedSubAccordion}
        handleSubAccordionChange={handleSubAccordionChange}
      />
      <SystemDialog open={open} onClose={handleClose} system={currentSystem} onChange={handleChange} onSubmit={handleSubmit} />

      <Dialog open={deleteDialogOpen} onClose={cancelDelete}>
        <DialogTitle>System löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sind Sie sicher, dass Sie dieses System löschen möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="secondary">Abbrechen</Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>Löschen</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default App;